:root {
  --primary: oklch(0.6 0.118 184.704);
  --primary-dark: oklch(0.437 0.078 188.216);
  --primary-active: #2c965f;
  --primary-color: var(--primary);
  --primary-bg: var(--primary-dark);
  --primary-light-bg: #00353F;
  --primary-lighter-bg: var(--primary-bg);
  --primary-border: oklch(0.511 0.096 186.391);
  --primary-200: #004D5A;
  --border-primary: var(--primary);
  --btn-primary: var(--primary);
  --date-active-bg: var(--primary);
  --input-padding: 6px 8px;
  --primary-shadow: 0 0 0 2px hsl(145 40% 85% / 1);
  --btn-primary-focus-shadow: var(--primary-shadow);
  --btn-primary-active-shadow: var(--primary-shadow);
  --checkbox-focus-shadow: var(--primary-shadow);
  --control-bg: #f3f4f6;
}

body {
  letter-spacing: 0 !important;
}

.for-login .page-card .page-card-body input[type=text],
.for-login .page-card .page-card-body input[type=password],
.for-login .page-card .page-card-body input[type=email],
.for-signup .page-card .page-card-body input[type=text],
.for-signup .page-card .page-card-body input[type=password],
.for-signup .page-card .page-card-body input[type=email],
.for-signup .page-card .page-card-body input[type=tel],
.for-forgot .page-card .page-card-body input[type=text],
.for-forgot .page-card .page-card-body input[type=password],
.for-forgot .page-card .page-card-body input[type=email],
.for-forgot .page-card .page-card-body input[type=tel],
.for-login-with-email-link .page-card .page-card-body input[type=text],
.for-login-with-email-link .page-card .page-card-body input[type=password],
.for-login-with-email-link .page-card .page-card-body input[type=email] {
  border: 1px solid #d1d5dc;
  letter-spacing: 0 !important;
  background-color: #ffffff !important;
}

.for-login .page-card .btn.btn-primary,
.for-signup .page-card .btn.btn-primary,
.for-forgot .page-card .btn.btn-primary,
.for-login-with-email-link .page-card .btn.btn-primary {
  background-color: var(--primary) !important;
  color: #fff;
}

.for-login .page-card .btn.btn-primary:focus,
.for-login .page-card .btn.btn-primary.focus,
.for-login .page-card .btn.btn-primary:active,
.for-login .page-card .btn.btn-primary.active,
.for-signup .page-card .btn.btn-primary:focus,
.for-signup .page-card .btn.btn-primary.focus,
.for-signup .page-card .btn.btn-primary:active,
.for-signup .page-card .btn.btn-primary.active,
.for-forgot .page-card .btn.btn-primary:focus,
.for-forgot .page-card .btn.btn-primary.focus,
.for-forgot .page-card .btn.btn-primary:active,
.for-forgot .page-card .btn.btn-primary.active,
.for-login-with-email-link .page-card .btn.btn-primary:focus,
.for-login-with-email-link .page-card .btn.btn-primary.focus,
.for-login-with-email-link .page-card .btn.btn-primary:active,
.for-login-with-email-link .page-card .btn.btn-primary.active {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 137, 0.2) !important;
}

.for-login .page-card .btn:not(.btn-md):not(.btn-lg),
.for-signup .page-card .btn:not(.btn-md):not(.btn-lg),
.for-forgot .page-card .btn:not(.btn-md):not(.btn-lg),
.for-login-with-email-link .page-card .btn:not(.btn-md):not(.btn-lg) {
  padding: 6px 8px !important;
}

.for-login .page-card .page-card-body .field-icon,
.for-signup .page-card .page-card-body .field-icon,
.for-forgot .page-card .page-card-body .field-icon,
.for-login-with-email-link .page-card .page-card-body .field-icon,
.for-email-login .page-card .page-card-body .field-icon,
.for-login .page-card .page-card-body .password-field .toggle-password,
.for-signup .page-card .page-card-body .password-field .toggle-password,
.for-forgot .page-card .page-card-body .password-field .toggle-password,
.for-login-with-email-link .page-card .page-card-body .password-field .toggle-password,
.for-email-login .page-card .page-card-body .password-field .toggle-password {
  top: 10px !important;
}

.form-control {
  height: 36px;
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgba(124, 124, 124, 0.05);
  background-color: #fff;
  border-color: #e2e2e2;
}

.chat-bubble {
  background: var(--primary) !important;
}

.navbar-light {
  border-bottom: 1px solid #e2e2e2;
  background: oklch(0.437 0.078 188.216);
  color: white;
}

.navbar-light .navbar-brand {
  color: white;
}

body:not([data-path=signup].full-width) .container-md,
body:not([data-path=signup].full-width) .container-xl,
body:not([data-path=signup].full-width) .container-lg,
body:not([data-path=signup].full-width) .container-sm,
body:not([data-path=signup].full-width) .container {
  width: 100%;
  max-width: 98%;
}

.sidebar-column {
  background: var(--primary-bg) !important;
}

.sidebar-item a {
  display: block;
  padding: 0.25rem 0.5rem;
  margin-top: 0.25rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 1440px) {
  .container {
    padding-left: 5px;
    padding-right: 0;
  }
}
.web-sidebar {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.page-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_content .empty-apps-state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}